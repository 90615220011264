import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { Events } from '@ionic/angular';
import { environment } from '../../../environments/environment';
import { LocaleService } from 'src/app/_services/locale.service';
import { Router } from '@angular/router';

@Component({
  selector: 'lang-selector',
  templateUrl: './lang-selector.component.html'
})
export class LangSelectorComponent implements OnInit  {

  public environment = environment;
  public externalTopLink = null;
  public user: any;
  public locale: string = this.localeService.getLocale();
  public langs:  string[] = environment.availableLangs;
  public showLangSelector: boolean = environment.availableLangs.length > 1;


  @Input() showLabel: boolean;
  @Output() change = new EventEmitter<string>();

  constructor(
    private localeService:  LocaleService,
    private events: Events,
    private cdr: ChangeDetectorRef
  ) {
  }

  ngOnInit() {
    this.locale = this.localeService.getLocale();
    this.cdr.detectChanges();
  }

  changeLang($event){
    this.change.emit($event.target.value);
    this.locale = $event.target.value;
    this.cdr.detectChanges();
    this.localeService.changeLocale($event.target.value);
  }

}
