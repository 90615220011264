import { LocaleService } from '../_services/locale.service';
import * as i0 from "@angular/core";
import * as i1 from "../_services/locale.service";
export class CategoriesDataProvider {
    constructor(localeService) {
        this.localeService = localeService;
        this.categories = [];
        this.translatedCategories = [];
    }
    setCategories(categories) {
        this.categories = categories;
        return this;
    }
    getCategories() {
        return this.categories;
    }
    getCategory(idCategory) {
        return this.categories.find(category => category.id === idCategory);
    }
    getTranslatedCategories() {
        this.translatedCategories = [];
        this.categories.forEach(category => {
            this.translatedCategories.push(this.getTranslatedCategory(category.id));
        });
        return this.translatedCategories;
    }
    getTranslatedCategory(id) {
        const translatedCategory = Object.assign({}, this.getCategory(id));
        const locale = this.localeService.getLocale();
        if (translatedCategory.translations.length > 0) {
            const localeTranslation = translatedCategory.translations.find((translation) => translation.locale === locale);
            if (localeTranslation) {
                translatedCategory.name = localeTranslation.name;
                translatedCategory.description = localeTranslation.description;
            }
        }
        return translatedCategory;
    }
}
CategoriesDataProvider.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CategoriesDataProvider_Factory() { return new CategoriesDataProvider(i0.ɵɵinject(i1.LocaleService)); }, token: CategoriesDataProvider, providedIn: "root" });
