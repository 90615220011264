import { Storage } from '@ionic/storage';
import { HttpClient } from '@angular/common/http';
import { Platform, Events } from '@ionic/angular';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable, BehaviorSubject, from } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { LocaleService } from './locale.service';
import * as i0 from "@angular/core";
import * as i1 from "@ionic/storage";
import * as i2 from "@angular/common/http";
import * as i3 from "@ionic/angular";
import * as i4 from "@angular/router";
import * as i5 from "./locale.service";
const helper = new JwtHelperService();
const TOKEN_KEY = 'jwt-token';
const API_KEY = environment.apiKey;
export class AuthService {
    constructor(storage, http, plt, events, router, localeService) {
        this.storage = storage;
        this.http = http;
        this.plt = plt;
        this.events = events;
        this.router = router;
        this.localeService = localeService;
        this.userData = new BehaviorSubject(null);
        this.authToken = new BehaviorSubject(null);
        this.locale = null;
        this.loginUrl = null;
        this.registerUrl = null;
        this.recoveryUrl = null;
        this.changePasswordUrl = null;
        this.appRecoverUrl = null;
        this.allowInsecurePasswords = environment.allowInsecurePasswords;
        this.loadStoredToken();
    }
    loadStoredToken() {
        let platformObs = from(this.plt.ready());
        this.user = platformObs.pipe(switchMap(() => {
            return from(this.storage.get(TOKEN_KEY));
        }), map(token => {
            if (token) {
                let decoded = helper.decodeToken(token);
                if (helper.isTokenExpired(token)) {
                    return null;
                }
                this.authToken.next(token);
                this.userData.next(decoded);
                this.events.publish('userloggedin', decoded);
                return true;
            }
            else {
                return null;
            }
        }));
    }
    getAuthToken() {
        return this.authToken.getValue();
    }
    login(credentials) {
        this.loginUrl = `${environment.apiUrl}/login_check?l=${this.localeService.getLocale()}`;
        return this.http.post(this.loginUrl, { _username: credentials.username, _password: credentials.pw, _api_key: API_KEY }).pipe(map(response => {
            return response.token;
        }), switchMap(token => {
            let decoded = helper.decodeToken(token);
            this.authToken.next(token);
            this.userData.next(decoded);
            let storageObs = from(this.storage.set(TOKEN_KEY, token));
            this.events.publish('loginsuccesfull', decoded);
            return storageObs;
        }));
    }
    register(username, pw, name, surname, email) {
        this.registerUrl = `${environment.apiUrl}/register?l=${this.localeService.getLocale()}`;
        return this.http.post(this.registerUrl, {
            _api_key: API_KEY,
            _username: username,
            _password: pw,
            _name: name,
            _surname: surname,
            _email: email
        }).pipe(map(response => {
            return response;
        }), switchMap(() => this.login({ username, pw })));
    }
    // recovery(username: string, email: string): Observable<any> {
    recovery(username) {
        this.recoveryUrl = `${environment.apiUrl}/recovery?l=${this.localeService.getLocale()}`;
        this.appRecoverUrl = `${environment.appUrl}/${this.localeService.getLocale()}/change-password`;
        return this.http.post(this.recoveryUrl, {
            _url: this.appRecoverUrl,
            _username: username,
            // _email: email,
            _api_key: API_KEY,
            _mail_transport: environment.mailTransport,
            _mail_brand_name: environment.brandName
        }).pipe(map(response => {
            return response.message ? response.message : '';
        }));
    }
    changePassword(password, token) {
        this.changePasswordUrl = `${environment.apiUrl}/change-password?l=${this.localeService.getLocale()}`;
        return this.http.post(this.changePasswordUrl, {
            _password: password,
            _token: token,
            _api_key: API_KEY
        }).pipe(map(response => {
            return response.message ? response.message : '';
        }));
    }
    getUser() {
        return this.userData.getValue();
    }
    logout() {
        localStorage.removeItem('flyingSocioSanitaryProfile');
        this.storage.remove(TOKEN_KEY).then(() => {
            this.router.navigateByUrl('/');
            this.userData.next(null);
        });
    }
    isValidPassword(plainPassword) {
        if (this.allowInsecurePasswords || !plainPassword) {
            return true;
        }
        return /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/.test(plainPassword);
    }
}
AuthService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.ɵɵinject(i1.Storage), i0.ɵɵinject(i2.HttpClient), i0.ɵɵinject(i3.Platform), i0.ɵɵinject(i3.Events), i0.ɵɵinject(i4.Router), i0.ɵɵinject(i5.LocaleService)); }, token: AuthService, providedIn: "root" });
