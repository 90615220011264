import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Events } from '@ionic/angular';
import { AuthService } from './auth.service';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { LocaleService } from './locale.service';


@Injectable({
  providedIn: 'root'
})
export class CollectiveService {

  constructor(
    private http: HttpClient,
    private events: Events,
    private auth: AuthService,
    private localeService: LocaleService
  ) {
  }

  getPublicData(){
    let serviceUrl = `${environment.apiUrl}/${environment.apiVersion}/anonymous/collectives/get-public-data?l=${this.localeService.getLocale()}`;
    let headers = new  HttpHeaders().set('api-key', environment.apiKey);

    return new Promise((resolve, reject) => {
      this.http.get(serviceUrl, {headers: headers})
      .subscribe(
        data => {
          if (data['code'] == 200) {
            resolve(data['data']);
          }
        }
      );
    });
  }

}
