/**
 * Entorno pre. Genera www
 *
 * Compilar:
 *
 * - ng run app:build:pre [--options]
 * - ionic build --configuration=pre [--options]
 *
 */

import { common } from './environment.common';
import { custom } from './environment.custom';

const env = {
  // habilita el service worker
  production: true,

  // conexión api
  apiUrl: 'https://apidemo.alares.org/api',

  // url de la app
  appUrl: custom.appUrlDomain + '.org',

  // analytics
  analyticsStatus: false,

  // testamentis deprecated
  testamentisHereditario: 'https://dev.testamentis.com/testamento-online/hereditario',
  testamentisModificacion: 'https://dev.testamentis.com/testamento-online/modificacion',
  testamentisVital: 'https://dev.testamentis.com/testamento-online/vital',

  // testamentis
  testamentisHereditary: 'https://formularios.alares.org/form/create/1edb1d7a-7ca0-6f92-958c-418168dd86b1/embed',
  testamentisLiving: 'https://formularios.alares.org/form/create/1edb1d7b-e514-6ee2-9954-05c69609ab40/embed'
};

// env es el último en cargar y sobreescribe cambios anteriores
export const environment = {...common, ...custom, ...env};
