import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Events } from '@ionic/angular';
import { AuthService } from './auth.service';
import { LocaleService } from './locale.service';


@Injectable({
  providedIn: 'root'
})
export class WidgetsService {

  constructor(
    private http: HttpClient,
    private events: Events,
    private auth: AuthService,
    private localeService: LocaleService
  ) {
  }

  getAll() {
    let serviceUrl = `${environment.apiUrl}/${environment.apiVersion}`;
    let headers = null;

    if (environment.apiVersion != 'v3') {
      return;
    }

    if (!environment.protected) {
      serviceUrl += '/anonymous';
      headers = new  HttpHeaders().set('api-key', environment.apiKey);
    } else {
      let authToken = this.auth.getAuthToken();
      headers = new  HttpHeaders().set('Authorization', `Bearer ${authToken}`);
    }
    serviceUrl += `/widgets/list?l=${this.localeService.getLocale()}`;

    return this.http.get(serviceUrl, {headers: headers})
    .subscribe(
      data => {
        if (data['code'] == 200) {
          this.events.publish('widgetsreceived', data['data']);
        }
      }
    );
  }

}
