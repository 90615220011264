import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';
import { JumbotronComponent } from './jumbotron.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    RouterModule,
    TranslateModule
  ],
  declarations: [JumbotronComponent],
  exports: [JumbotronComponent]
})
export class JumbotronModule {}
