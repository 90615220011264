import { Injectable } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { map, mapTo } from 'rxjs/operators';
import { Widget } from '../_models/widget';
import { LocaleService } from '../_services/locale.service';


@Injectable({
    providedIn: 'root'
})
export class WidgetsDataProvider {
    private widgets: Widget[] = [];
    private translatedWidgets: Widget[] = [];

    constructor(
        private localeService: LocaleService
    ) {
    }

    setWidgets (widgets: Widget[]) {
        this.widgets = widgets;
    }

    getWidgets() {
        return this.widgets;
    }

    getTranslatedWidgets() {
        if (this.widgets.length == 0) {
            return;
        }
        this.translatedWidgets = [];
        this.widgets.forEach(service => {
            this.translatedWidgets.push(this.getTranslatedWidget(service.id));
        });

        return this.translatedWidgets;
    }

    getById(id: Number) {
        return this.widgets.find(widget => widget.id === id);
    }

    getByPosition(position: string) {
        return this.widgets.filter(widget => widget.position === position);
    }

    getTranslatedByPosition(position: string)
    {
        let widgets = this.getByPosition(position);
        let translatedWidgets = [];

        widgets.forEach(widget => {
            translatedWidgets.push(this.getTranslatedWidget(widget.id));
        });

        return translatedWidgets;
    }

    getTranslatedWidget(id: number) {
        if (this.widgets.length == 0) {
            return;
        }
        let translatedWidget = {...this.getById(id)};
        let locale = this.localeService.getLocale();
        if (translatedWidget.translations.length > 0) {
            let localeTranslation: any = translatedWidget.translations.find( (translation: any) => translation.locale === locale );
            if (localeTranslation) {
                translatedWidget.name = localeTranslation.name;
                translatedWidget.description = localeTranslation.description;
                translatedWidget.bypassLink = localeTranslation.bypassLink;
            }
        }
        return translatedWidget;
    }
}
