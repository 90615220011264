/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i2 from "@ionic/angular";
import * as i3 from "@ngx-translate/core";
import * as i4 from "@angular/forms";
import * as i5 from "@angular/common";
import * as i6 from "./lang-selector.component";
import * as i7 from "../../_services/locale.service";
var styles_LangSelectorComponent = [];
var RenderType_LangSelectorComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_LangSelectorComponent, data: {} });
export { RenderType_LangSelectorComponent as RenderType_LangSelectorComponent };
function View_LangSelectorComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "ion-label", [["class", "lang-selector-label"]], null, null, null, i1.View_IonLabel_0, i1.RenderType_IonLabel)), i0.ɵdid(1, 49152, null, 0, i2.IonLabel, [i0.ChangeDetectorRef, i0.ElementRef, i0.NgZone], null, null), (_l()(), i0.ɵted(2, 0, ["", ""])), i0.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i0.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 3).transform("langselector.name")); _ck(_v, 2, 0, currVal_0); }); }
function View_LangSelectorComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "ion-select-option", [], null, null, null, i1.View_IonSelectOption_0, i1.RenderType_IonSelectOption)), i0.ɵdid(1, 49152, null, 0, i2.IonSelectOption, [i0.ChangeDetectorRef, i0.ElementRef, i0.NgZone], { value: [0, "value"] }, null), (_l()(), i0.ɵted(2, 0, ["", ""])), i0.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i0.ChangeDetectorRef])], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 3).transform(("langselector." + _v.context.$implicit))); _ck(_v, 2, 0, currVal_1); }); }
function View_LangSelectorComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "ion-item", [["class", "lang-selector"]], null, null, null, i1.View_IonItem_0, i1.RenderType_IonItem)), i0.ɵdid(1, 49152, null, 0, i2.IonItem, [i0.ChangeDetectorRef, i0.ElementRef, i0.NgZone], null, null), (_l()(), i0.ɵand(0, null, 0, 0, null, View_LangSelectorComponent_2)), (_l()(), i0.ɵeld(3, 0, null, 0, 5, "ion-select", [["placeholder", "Idioma"]], null, [[null, "ionChange"], [null, "ionBlur"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ionBlur" === en)) {
        var pd_0 = (i0.ɵnov(_v, 6)._handleBlurEvent($event.target) !== false);
        ad = (pd_0 && ad);
    } if (("ionChange" === en)) {
        var pd_1 = (i0.ɵnov(_v, 6)._handleChangeEvent($event.target) !== false);
        ad = (pd_1 && ad);
    } if (("ionChange" === en)) {
        var pd_2 = (_co.changeLang($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i1.View_IonSelect_0, i1.RenderType_IonSelect)), i0.ɵprd(5120, null, i4.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.SelectValueAccessor]), i0.ɵdid(5, 49152, null, 0, i2.IonSelect, [i0.ChangeDetectorRef, i0.ElementRef, i0.NgZone], { placeholder: [0, "placeholder"], value: [1, "value"] }, null), i0.ɵdid(6, 16384, null, 0, i2.SelectValueAccessor, [i0.ElementRef], null, null), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_LangSelectorComponent_3)), i0.ɵdid(8, 278528, null, 0, i5.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "Idioma"; var currVal_1 = _co.locale; _ck(_v, 5, 0, currVal_0, currVal_1); var currVal_2 = _co.langs; _ck(_v, 8, 0, currVal_2); }, null); }
function View_LangSelectorComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "ion-select-option", [], null, null, null, i1.View_IonSelectOption_0, i1.RenderType_IonSelectOption)), i0.ɵdid(1, 49152, null, 0, i2.IonSelectOption, [i0.ChangeDetectorRef, i0.ElementRef, i0.NgZone], { value: [0, "value"] }, null), (_l()(), i0.ɵted(2, 0, ["", ""])), i0.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i0.ChangeDetectorRef])], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 3).transform(("langselector." + _v.context.$implicit))); _ck(_v, 2, 0, currVal_1); }); }
function View_LangSelectorComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "ion-select", [["placeholder", "Idioma"]], null, [[null, "ionChange"], [null, "ionBlur"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ionBlur" === en)) {
        var pd_0 = (i0.ɵnov(_v, 3)._handleBlurEvent($event.target) !== false);
        ad = (pd_0 && ad);
    } if (("ionChange" === en)) {
        var pd_1 = (i0.ɵnov(_v, 3)._handleChangeEvent($event.target) !== false);
        ad = (pd_1 && ad);
    } if (("ionChange" === en)) {
        var pd_2 = (_co.changeLang($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i1.View_IonSelect_0, i1.RenderType_IonSelect)), i0.ɵprd(5120, null, i4.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.SelectValueAccessor]), i0.ɵdid(2, 49152, null, 0, i2.IonSelect, [i0.ChangeDetectorRef, i0.ElementRef, i0.NgZone], { placeholder: [0, "placeholder"], value: [1, "value"] }, null), i0.ɵdid(3, 16384, null, 0, i2.SelectValueAccessor, [i0.ElementRef], null, null), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_LangSelectorComponent_5)), i0.ɵdid(5, 278528, null, 0, i5.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "Idioma"; var currVal_1 = _co.locale; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _co.langs; _ck(_v, 5, 0, currVal_2); }, null); }
export function View_LangSelectorComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_LangSelectorComponent_1)), i0.ɵdid(1, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_LangSelectorComponent_4)), i0.ɵdid(3, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.showLangSelector && _co.showLabel); _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.showLangSelector && !_co.showLabel); _ck(_v, 3, 0, currVal_1); }, null); }
export function View_LangSelectorComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "lang-selector", [], null, null, null, View_LangSelectorComponent_0, RenderType_LangSelectorComponent)), i0.ɵdid(1, 114688, null, 0, i6.LangSelectorComponent, [i7.LocaleService, i2.Events, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LangSelectorComponentNgFactory = i0.ɵccf("lang-selector", i6.LangSelectorComponent, View_LangSelectorComponent_Host_0, { showLabel: "showLabel" }, { change: "change" }, []);
export { LangSelectorComponentNgFactory as LangSelectorComponentNgFactory };
