import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LocaleService {

  private locale: string = null;

  constructor(
    private translate: TranslateService,
    private router: Router
  ) {
    this.translate.setDefaultLang(environment.defaultLang);
  }

  setLocale(locale: string) {
    this.locale = locale;
    this.translate.use(this.locale);
  }

  changeLocale(newLocale: string) {
    let arrayURL = this.router.url.split('/');
    arrayURL[1] = newLocale;
    let newURL = arrayURL.join('/');
    window.location.href = newURL;
  }

  getLocale() {
    return this.locale;
  }
}
