import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { AuthService } from '../_services/auth.service';
import { AlertController } from '@ionic/angular';
import { take, map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "../_services/auth.service";
import * as i2 from "@angular/router";
import * as i3 from "@ionic/angular";
import * as i4 from "@ngx-translate/core";
export class AuthGuard {
    constructor(auth, router, alertCtrl, translateService) {
        this.auth = auth;
        this.router = router;
        this.alertCtrl = alertCtrl;
        this.translateService = translateService;
        this.messages = null;
        this.result = null;
    }
    canActivate(route) {
        return this.auth.user.pipe(take(1), map(user => {
            if (!user && environment.protected) {
                this.translateService.get([
                    'security.errors.403',
                    'security.errors.403'
                ], {})
                    .subscribe((messages) => {
                    this.messages = messages;
                    this.alertCtrl.create({
                        header: 'Error',
                        message: this.messages['security.errors.403'],
                        buttons: ['OK']
                    }).then(alert => alert.present());
                    this.router.navigateByUrl('/');
                });
                return false;
            }
            else {
                return true;
            }
        }));
    }
}
AuthGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthGuard_Factory() { return new AuthGuard(i0.ɵɵinject(i1.AuthService), i0.ɵɵinject(i2.Router), i0.ɵɵinject(i3.AlertController), i0.ɵɵinject(i4.TranslateService)); }, token: AuthGuard, providedIn: "root" });
