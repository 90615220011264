import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../_services/auth.service';
import { AlertController } from '@ionic/angular';
import { take, map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment'

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  public messages: object = null;
  private result: boolean = null;

  constructor(
    private auth: AuthService,
    private router: Router,
    private alertCtrl: AlertController,
    private translateService: TranslateService
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.auth.user.pipe(
      take(1),
      map(user => {
        if (!user && environment.protected) {
          this.translateService.get([
            'security.errors.403',
            'security.errors.403'
          ], {})
          .subscribe((messages: object) => {
            this.messages = messages;
            this.alertCtrl.create({
              header: 'Error',
              message: this.messages['security.errors.403'],
              buttons: ['OK']
            }).then(alert => alert.present());
            this.router.navigateByUrl('/');
          });
          return false;
        } else {
          return true;
        }
      })
    )
  }
}
