import { NgcCookieConsentConfig } from 'ngx-cookieconsent';
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
const DEFAULT_SWIPER_CONFIG = {
    direction: 'horizontal'
};
const cookieConfig = {
    "cookie": {
        "domain": environment.appUrl
    },
    "position": "bottom-right",
    "theme": "classic",
    "palette": {
        "popup": {
            "background": "#000000",
            "text": "#ffffff",
            "link": "#ffffff"
        },
        "button": {
            "background": "#f1d600",
            "text": "#000000",
            "border": "transparent"
        }
    },
    "type": "info",
    "content": {
        "message": "...",
        "dismiss": "..",
        "deny": "...",
        "link": "...",
        "href": "#",
        "policy": "..."
    }
};
export function createTranslateLoader(http) {
    return new TranslateHttpLoader(http, `${environment.apiUrl}/${environment.apiVersion}/translation/${environment.apiKey}/`, '.json');
}
const ɵ0 = (createTranslateLoader), ɵ1 = DEFAULT_SWIPER_CONFIG;
export class AppModule {
}
export { ɵ0, ɵ1 };
