import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Events } from '@ionic/angular';
import { AuthService } from './auth.service';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { LocaleService } from 'src/app/_services/locale.service';


@Injectable({
  providedIn: 'root'
})
export class PagesService {

  constructor(
    private http: HttpClient,
    private events: Events,
    private auth: AuthService,
    private localeService: LocaleService
  ) {
  }

  getAll() {
    let serviceUrl = `${environment.apiUrl}/${environment.apiVersion}`;
    let headers = null;

    if (environment.apiVersion == 'v2') {
      serviceUrl += `/pages?l=${this.localeService.getLocale()}`;
      let authToken = this.auth.getAuthToken();
      headers = new  HttpHeaders().set('Authorization', `Bearer ${authToken}`);
    }

    if (environment.apiVersion == 'v3') {
      if (!environment.protected) {
        serviceUrl += '/anonymous';
        headers = new  HttpHeaders().set('api-key', environment.apiKey);
      } else {
        let authToken = this.auth.getAuthToken();
        headers = new  HttpHeaders().set('Authorization', `Bearer ${authToken}`);
      }
      serviceUrl += `/pages/list?l=${this.localeService.getLocale()}`;
    }

    return this.http.get(serviceUrl, {headers: headers})
    .subscribe(
      data => {
        this.events.publish('pagesreceived', data['data']);
      }
    );
  }

}
