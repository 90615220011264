import { Injectable } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { map, mapTo } from 'rxjs/operators';
import { Page } from '../_models/page'
import { LocaleService } from 'src/app/_services/locale.service';

@Injectable({
  providedIn: 'root'
})
export class PagesDataProvider {
  private pages = [];

  constructor(
    private localeService: LocaleService
  ) { }

  setPages(pages: Page[]) {
    this.pages = pages;
  }

  getPages() {
    return this.pages;
  }

  getByType(type: Number) {
    return this.pages['type-' + type]
  }

  getTranslatedByType(type: Number) {
    if (this.pages.length == 0) {
      return;
    }
    let locale = this.localeService.getLocale();
    if(typeof this.pages['type-' + type] === 'undefined') {
      return;
    }
    let page = { ...this.pages['type-' + type] };
    if (page.translations.length > 0) {
      let translation = page.translations.find(translation => translation.locale === locale);
      if (translation) {
        page.name = translation.name;
        page.description = translation.description;
      }
    }
    return page;
  }

  initPages() {
    let appPages = [];

    // {
    //   title: this.getTranslatedByType(0).name,
    //   url: '/about',
    //   icon: 'information-circle-outline'
    // },
    // {
    //   title: this.getTranslatedByType(1).name,
    //   url: '/beneficiaries',
    //   icon: 'contacts'
    // },
    // {
    //   title: this.getTranslatedByType(2).name,
    //   url: '/faq',
    //   icon: 'help-circle-outline'
    // }

    if (this.getPages()['type-0']) {
      appPages.push({
        title: this.getTranslatedByType(0).name,
        url: '/about',
        icon: 'information-circle-outline'
      });
    }

    if (this.getPages()['type-1']) {
      appPages.push({
        title: this.getTranslatedByType(1).name,
        url: '/beneficiaries',
        icon: 'contacts'
      });
    }

    if (this.getPages()['type-2']) {
      appPages.push({
        title: this.getTranslatedByType(2).name,
        url: '/faq',
        icon: 'help-circle-outline'
      });
    }

    if (this.getPages()['type-3']) {
      appPages.push({
        title: this.getTranslatedByType(3).name,
        url: '/id-4',
        icon: 'globe'
      });
    }

    if (this.getPages()['type-4']) {
      appPages.push({
        title: this.getTranslatedByType(4).name,
        url: '/id-5',
        icon: 'globe'
      });
    }

    return appPages;
  }
}
