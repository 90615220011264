import { LocaleService } from '../_services/locale.service';
import * as i0 from "@angular/core";
import * as i1 from "../_services/locale.service";
export class OpinionsDataProvider {
    constructor(localeService) {
        this.localeService = localeService;
        this.opinions = [];
        this.translatedOpinions = [];
    }
    setOpinions(opinions) {
        this.opinions = opinions;
    }
    getOpinions() {
        return this.opinions;
    }
    getTranslatedOpinions() {
        if (this.opinions.length == 0) {
            return;
        }
        this.translatedOpinions = [];
        this.opinions.forEach(service => {
            this.translatedOpinions.push(this.getTranslatedOpinion(service.id));
        });
        return this.translatedOpinions;
    }
    getById(id) {
        return this.opinions.find(opinion => opinion.id === id);
    }
    getTranslatedOpinion(id) {
        if (this.opinions.length == 0) {
            return;
        }
        let translatedOpinion = Object.assign({}, this.getById(id));
        let locale = this.localeService.getLocale();
        if (translatedOpinion.translations.length > 0) {
            let localeTranslation = translatedOpinion.translations.find((translation) => translation.locale === locale);
            if (localeTranslation) {
                translatedOpinion.description = localeTranslation.description;
            }
        }
        return translatedOpinion;
    }
}
OpinionsDataProvider.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function OpinionsDataProvider_Factory() { return new OpinionsDataProvider(i0.ɵɵinject(i1.LocaleService)); }, token: OpinionsDataProvider, providedIn: "root" });
