import * as tslib_1 from "tslib";
import { SwUpdate } from '@angular/service-worker';
import { interval } from 'rxjs';
import { ToastController } from '@ionic/angular';
import * as i0 from "@angular/core";
import * as i1 from "@angular/service-worker";
import * as i2 from "@ionic/angular";
export class UpdateService {
    constructor(updates, toastController) {
        this.updates = updates;
        this.toastController = toastController;
        if (this.updates.isEnabled) {
            this.updates.checkForUpdate().then(() => {
            });
            interval(10 * 60 * 1000).subscribe(() => {
                this.updates.checkForUpdate().then(() => {
                });
            });
        }
    }
    checkForUpdates() {
        this.updates.available.subscribe(event => this.promptUser(event));
    }
    promptUser(event) {
        this.updates.activateUpdate().then(() => {
            this.availableUpdateAlert();
        });
    }
    availableUpdateAlert() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const toast = yield this.toastController.create({
                mode: 'ios',
                message: 'Hay una actualización disponible',
                position: 'bottom',
                color: 'dark',
                buttons: [
                    {
                        side: 'end',
                        icon: 'refresh',
                        text: 'Actualizar',
                        handler: () => {
                            document.location.reload();
                        }
                    }
                ]
            });
            toast.present();
        });
    }
}
UpdateService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UpdateService_Factory() { return new UpdateService(i0.ɵɵinject(i1.SwUpdate), i0.ɵɵinject(i2.ToastController)); }, token: UpdateService, providedIn: "root" });
