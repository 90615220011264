import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "@angular/router";
export class LocaleService {
    constructor(translate, router) {
        this.translate = translate;
        this.router = router;
        this.locale = null;
        this.translate.setDefaultLang(environment.defaultLang);
    }
    setLocale(locale) {
        this.locale = locale;
        this.translate.use(this.locale);
    }
    changeLocale(newLocale) {
        let arrayURL = this.router.url.split('/');
        arrayURL[1] = newLocale;
        let newURL = arrayURL.join('/');
        window.location.href = newURL;
    }
    getLocale() {
        return this.locale;
    }
}
LocaleService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LocaleService_Factory() { return new LocaleService(i0.ɵɵinject(i1.TranslateService), i0.ɵɵinject(i2.Router)); }, token: LocaleService, providedIn: "root" });
