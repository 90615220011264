import { LocaleService } from '../_services/locale.service';
import * as i0 from "@angular/core";
import * as i1 from "../_services/locale.service";
export class WidgetsDataProvider {
    constructor(localeService) {
        this.localeService = localeService;
        this.widgets = [];
        this.translatedWidgets = [];
    }
    setWidgets(widgets) {
        this.widgets = widgets;
    }
    getWidgets() {
        return this.widgets;
    }
    getTranslatedWidgets() {
        if (this.widgets.length == 0) {
            return;
        }
        this.translatedWidgets = [];
        this.widgets.forEach(service => {
            this.translatedWidgets.push(this.getTranslatedWidget(service.id));
        });
        return this.translatedWidgets;
    }
    getById(id) {
        return this.widgets.find(widget => widget.id === id);
    }
    getByPosition(position) {
        return this.widgets.filter(widget => widget.position === position);
    }
    getTranslatedByPosition(position) {
        let widgets = this.getByPosition(position);
        let translatedWidgets = [];
        widgets.forEach(widget => {
            translatedWidgets.push(this.getTranslatedWidget(widget.id));
        });
        return translatedWidgets;
    }
    getTranslatedWidget(id) {
        if (this.widgets.length == 0) {
            return;
        }
        let translatedWidget = Object.assign({}, this.getById(id));
        let locale = this.localeService.getLocale();
        if (translatedWidget.translations.length > 0) {
            let localeTranslation = translatedWidget.translations.find((translation) => translation.locale === locale);
            if (localeTranslation) {
                translatedWidget.name = localeTranslation.name;
                translatedWidget.description = localeTranslation.description;
                translatedWidget.bypassLink = localeTranslation.bypassLink;
            }
        }
        return translatedWidget;
    }
}
WidgetsDataProvider.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function WidgetsDataProvider_Factory() { return new WidgetsDataProvider(i0.ɵɵinject(i1.LocaleService)); }, token: WidgetsDataProvider, providedIn: "root" });
