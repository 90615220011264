import { Injectable } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { map, mapTo } from 'rxjs/operators';
import { Service } from '../_models/service'
import { LocaleService } from '../_services/locale.service';

@Injectable({
    providedIn: 'root'
})
export class ServicesDataProvider {
    private services: Service[] = [];
    private orphans: Service[] = [];
    private translatedServices: Service[] = [];
    private translatedOrphanServices: Service[] = [];

    constructor(
        private localeService: LocaleService
    ) { }

    setServices (services: Service[]) {
        this.services = services;
        return this;
    }

    addService(service: Service) {
        this.services.push(service);
        return this;
    }

    addServices(services: Service[]) {
        this.services = this.services.concat(services);
        return this;
    }

    getServices() {
        return this.services;
    }

    getService(id: number) {
        if (this.services.length == 0) {
            return;
        }
        return this.services.find( service => service.id === id );
    }

    getTranslatedServices() {
        this.translatedServices = [];
        this.services.forEach(service => {
            this.translatedServices.push(this.getTranslatedService(service.id));
        });

        return this.translatedServices;
    }

    getTranslatedService(id: number) {
        if (this.services.length == 0) {
            return;
        }
        let translatedService = {...this.getService(id)};
        let locale = this.localeService.getLocale();
        if (translatedService.translations.length > 0) {
            let localeTranslation: any = translatedService.translations.find( (translation: any) => translation.locale === locale );
            if (localeTranslation) {
                translatedService.name = localeTranslation.name;
                translatedService.description = localeTranslation.description;
            }
        }
        return translatedService;
    }

    setOrphanServices (orphans: Service[]) {
        this.orphans = orphans;
        return this;
    }

    getOrphanServices() {
        return this.orphans;
    }

    getOrphanTranslatedServices() {
        this.translatedOrphanServices = [];
        this.orphans.forEach(service => {
            this.translatedOrphanServices.push(this.getTranslatedService(service.id));
        });
        return this.translatedOrphanServices;
    }
}
