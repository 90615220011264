import { LocaleService } from 'src/app/_services/locale.service';
import * as i0 from "@angular/core";
import * as i1 from "../_services/locale.service";
export class PagesDataProvider {
    constructor(localeService) {
        this.localeService = localeService;
        this.pages = [];
    }
    setPages(pages) {
        this.pages = pages;
    }
    getPages() {
        return this.pages;
    }
    getByType(type) {
        return this.pages['type-' + type];
    }
    getTranslatedByType(type) {
        if (this.pages.length == 0) {
            return;
        }
        let locale = this.localeService.getLocale();
        if (typeof this.pages['type-' + type] === 'undefined') {
            return;
        }
        let page = Object.assign({}, this.pages['type-' + type]);
        if (page.translations.length > 0) {
            let translation = page.translations.find(translation => translation.locale === locale);
            if (translation) {
                page.name = translation.name;
                page.description = translation.description;
            }
        }
        return page;
    }
    initPages() {
        let appPages = [];
        // {
        //   title: this.getTranslatedByType(0).name,
        //   url: '/about',
        //   icon: 'information-circle-outline'
        // },
        // {
        //   title: this.getTranslatedByType(1).name,
        //   url: '/beneficiaries',
        //   icon: 'contacts'
        // },
        // {
        //   title: this.getTranslatedByType(2).name,
        //   url: '/faq',
        //   icon: 'help-circle-outline'
        // }
        if (this.getPages()['type-0']) {
            appPages.push({
                title: this.getTranslatedByType(0).name,
                url: '/about',
                icon: 'information-circle-outline'
            });
        }
        if (this.getPages()['type-1']) {
            appPages.push({
                title: this.getTranslatedByType(1).name,
                url: '/beneficiaries',
                icon: 'contacts'
            });
        }
        if (this.getPages()['type-2']) {
            appPages.push({
                title: this.getTranslatedByType(2).name,
                url: '/faq',
                icon: 'help-circle-outline'
            });
        }
        if (this.getPages()['type-3']) {
            appPages.push({
                title: this.getTranslatedByType(3).name,
                url: '/id-4',
                icon: 'globe'
            });
        }
        if (this.getPages()['type-4']) {
            appPages.push({
                title: this.getTranslatedByType(4).name,
                url: '/id-5',
                icon: 'globe'
            });
        }
        return appPages;
    }
}
PagesDataProvider.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PagesDataProvider_Factory() { return new PagesDataProvider(i0.ɵɵinject(i1.LocaleService)); }, token: PagesDataProvider, providedIn: "root" });
