import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { AuthService } from '../_services/auth.service';
import { AlertController } from '@ionic/angular';
import { take, map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../_services/auth.service";
import * as i2 from "@angular/router";
import * as i3 from "@ionic/angular";
export class SociosanitaryGuard {
    constructor(auth, router, alertCtrl) {
        this.auth = auth;
        this.router = router;
        this.alertCtrl = alertCtrl;
    }
    canActivate(route) {
        return this.auth.user.pipe(take(1), map(user => {
            if (!user) {
                return false;
            }
            if (!this.auth.getUser().hasOwnProperty('socioSanitaryProfile')) {
                this.router.navigateByUrl('/service-index');
                return false;
            }
            return true;
        }));
    }
}
SociosanitaryGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SociosanitaryGuard_Factory() { return new SociosanitaryGuard(i0.ɵɵinject(i1.AuthService), i0.ɵɵinject(i2.Router), i0.ɵɵinject(i3.AlertController)); }, token: SociosanitaryGuard, providedIn: "root" });
