import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Events } from '@ionic/angular';
import { AuthService } from './auth.service';
import { LocaleService } from './locale.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@ionic/angular";
import * as i3 from "./auth.service";
import * as i4 from "./locale.service";
export class CollectiveService {
    constructor(http, events, auth, localeService) {
        this.http = http;
        this.events = events;
        this.auth = auth;
        this.localeService = localeService;
    }
    getPublicData() {
        let serviceUrl = `${environment.apiUrl}/${environment.apiVersion}/anonymous/collectives/get-public-data?l=${this.localeService.getLocale()}`;
        let headers = new HttpHeaders().set('api-key', environment.apiKey);
        return new Promise((resolve, reject) => {
            this.http.get(serviceUrl, { headers: headers })
                .subscribe(data => {
                if (data['code'] == 200) {
                    resolve(data['data']);
                }
            });
        });
    }
}
CollectiveService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CollectiveService_Factory() { return new CollectiveService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.Events), i0.ɵɵinject(i3.AuthService), i0.ɵɵinject(i4.LocaleService)); }, token: CollectiveService, providedIn: "root" });
