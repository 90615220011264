import { LocaleService } from 'src/app/_services/locale.service';
import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "../_services/locale.service";
export class CollectiveDataProvider {
    constructor(localeService) {
        this.localeService = localeService;
        this.hidrated = false;
        this.logo = null;
        this.logosm = null;
        this.publicBackground = null;
        this.bannerBackground = null;
        this.serviceEmail = null;
        this.serviceTelephone = null;
        this.emergencyTelephone = null;
        this.genderViolenceTelephone = null;
        this.dataSource = new BehaviorSubject({});
    }
    bindData(data) {
        this.logo = data.logo;
        this.logosm = data.logosm;
        this.publicBackground = data.publicBackground;
        this.bannerBackground = data.bannerBackground;
        this.serviceEmail = data.serviceEmail;
        this.serviceTelephone = data.serviceTelephone;
        this.emergencyTelephone = data.emergencyTelephone;
        this.genderViolenceTelephone = data.genderViolenceTelephone;
        this.dataSource.next(data);
        this.hidrated = true;
    }
    getData() {
        return this.dataSource.asObservable();
    }
    getPublicBackground() {
        return this.publicBackground;
    }
    getBannerBackground() {
        return this.bannerBackground;
    }
    getLogo() {
        return this.logo;
    }
    getLogosm() {
        return this.logosm;
    }
    getServiceEmail() {
        return this.serviceEmail;
    }
    getServiceTelephone() {
        return this.serviceTelephone;
    }
    getEmergencyTelephone() {
        return this.emergencyTelephone;
    }
    getGenderViolenceTelephone() {
        return this.genderViolenceTelephone;
    }
}
CollectiveDataProvider.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CollectiveDataProvider_Factory() { return new CollectiveDataProvider(i0.ɵɵinject(i1.LocaleService)); }, token: CollectiveDataProvider, providedIn: "root" });
