import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';
import { LangSelectorComponent } from './lang-selector.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    RouterModule,
    TranslateModule
  ],
  declarations: [LangSelectorComponent],
  exports:[LangSelectorComponent]
})
export class LangSelectorModule {}
