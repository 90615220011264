import { Injectable } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { map, mapTo } from 'rxjs/operators';
import { Feature } from '../_models/feature'
import { LocaleService } from '../_services/locale.service';


@Injectable({
    providedIn: 'root'
})
export class FeaturesDataProvider {
    private features: Feature[] = [];
    private translatedFeatures: Feature[] = [];

    constructor(
        private localeService: LocaleService
    ) { }

    setFeatures (features: Feature[]) {
        this.features = features;
    }

    getFeatures() {
        return this.features;
    }

    getTranslatedFeatures() {
        if (this.features.length == 0) {
            return;
        }
        this.translatedFeatures = [];
        this.features.forEach(service => {
            this.translatedFeatures.push(this.getTranslatedFeature(service.id));
        });

        return this.translatedFeatures;
    }

    getById(id: Number) {
        return this.features.find(feature => feature.id === id);
    }

    getTranslatedFeature(id: number) {
        if (this.features.length == 0) {
            return;
        }
        let translatedFeature = {...this.getById(id)};
        let locale = this.localeService.getLocale();
        if (translatedFeature.translations.length > 0) {
            let localeTranslation: any = translatedFeature.translations.find( (translation: any) => translation.locale === locale );
            if (localeTranslation) {
                translatedFeature.name = localeTranslation.name;
                translatedFeature.description = localeTranslation.description;
                translatedFeature.bypassLink = localeTranslation.bypassLink;
            }
        }
        return translatedFeature;
    }
}
