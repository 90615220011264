import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Events } from '@ionic/angular';
import { AuthService } from './auth.service';
import { LocaleService } from './locale.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@ionic/angular";
import * as i3 from "./auth.service";
import * as i4 from "./locale.service";
export class OpinionsService {
    constructor(http, events, auth, localeService) {
        this.http = http;
        this.events = events;
        this.auth = auth;
        this.localeService = localeService;
    }
    getAll() {
        let serviceUrl = `${environment.apiUrl}/${environment.apiVersion}`;
        let headers = null;
        if (environment.apiVersion != 'v3') {
            return;
        }
        if (!environment.protected) {
            serviceUrl += '/anonymous';
            headers = new HttpHeaders().set('api-key', environment.apiKey);
        }
        else {
            let authToken = this.auth.getAuthToken();
            headers = new HttpHeaders().set('Authorization', `Bearer ${authToken}`);
        }
        serviceUrl += `/opinions/list?l=${this.localeService.getLocale()}`;
        return this.http.get(serviceUrl, { headers: headers })
            .subscribe(data => {
            if (data['code'] == 200) {
                this.events.publish('opinionsreceived', data['data']);
            }
        });
    }
}
OpinionsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function OpinionsService_Factory() { return new OpinionsService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.Events), i0.ɵɵinject(i3.AuthService), i0.ɵɵinject(i4.LocaleService)); }, token: OpinionsService, providedIn: "root" });
