import { Injectable } from '@angular/core';
import { Category } from '../_models/category';
import { Service } from '../_models/service';
import { LocaleService } from '../_services/locale.service';

@Injectable({
    providedIn: 'root'
  })
export class CategoriesDataProvider {

    private categories: Category[] = [];
    private translatedCategories: Category[] = [];

    constructor(
      private localeService: LocaleService
    ) { }

    setCategories(categories: Category[]) {
        this.categories = categories;
        return this;
    }

    getCategories() {
        return this.categories;
    }

    getCategory(idCategory: number) {
        return this.categories.find(category => category.id === idCategory);
    }

    getTranslatedCategories() {
        this.translatedCategories = [];
        this.categories.forEach(category => {
            this.translatedCategories.push(this.getTranslatedCategory(category.id));
        });

        return this.translatedCategories;
    }

    getTranslatedCategory(id: number) {
        const translatedCategory = {...this.getCategory(id)};
        const locale = this.localeService.getLocale();
        if (translatedCategory.translations.length > 0) {
            const localeTranslation: any = translatedCategory.translations.find( (translation: any) => translation.locale === locale );
            if (localeTranslation) {
                translatedCategory.name = localeTranslation.name;
                translatedCategory.description = localeTranslation.description;
            }
        }
        return translatedCategory;
    }
}
