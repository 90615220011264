import { LocaleService } from '../_services/locale.service';
import * as i0 from "@angular/core";
import * as i1 from "../_services/locale.service";
export class ServicesDataProvider {
    constructor(localeService) {
        this.localeService = localeService;
        this.services = [];
        this.orphans = [];
        this.translatedServices = [];
        this.translatedOrphanServices = [];
    }
    setServices(services) {
        this.services = services;
        return this;
    }
    addService(service) {
        this.services.push(service);
        return this;
    }
    addServices(services) {
        this.services = this.services.concat(services);
        return this;
    }
    getServices() {
        return this.services;
    }
    getService(id) {
        if (this.services.length == 0) {
            return;
        }
        return this.services.find(service => service.id === id);
    }
    getTranslatedServices() {
        this.translatedServices = [];
        this.services.forEach(service => {
            this.translatedServices.push(this.getTranslatedService(service.id));
        });
        return this.translatedServices;
    }
    getTranslatedService(id) {
        if (this.services.length == 0) {
            return;
        }
        let translatedService = Object.assign({}, this.getService(id));
        let locale = this.localeService.getLocale();
        if (translatedService.translations.length > 0) {
            let localeTranslation = translatedService.translations.find((translation) => translation.locale === locale);
            if (localeTranslation) {
                translatedService.name = localeTranslation.name;
                translatedService.description = localeTranslation.description;
            }
        }
        return translatedService;
    }
    setOrphanServices(orphans) {
        this.orphans = orphans;
        return this;
    }
    getOrphanServices() {
        return this.orphans;
    }
    getOrphanTranslatedServices() {
        this.translatedOrphanServices = [];
        this.orphans.forEach(service => {
            this.translatedOrphanServices.push(this.getTranslatedService(service.id));
        });
        return this.translatedOrphanServices;
    }
}
ServicesDataProvider.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ServicesDataProvider_Factory() { return new ServicesDataProvider(i0.ɵɵinject(i1.LocaleService)); }, token: ServicesDataProvider, providedIn: "root" });
