import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { RecaptchaComponent } from './recaptcha.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule
  ],
  declarations: [RecaptchaComponent],
  exports: [RecaptchaComponent]
})
export class RecaptchaModule {}
