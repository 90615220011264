import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { interval } from 'rxjs';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class UpdateService {

  constructor(
    public updates: SwUpdate,
    private toastController: ToastController
  ) {
    if (this.updates.isEnabled) {
      this.updates.checkForUpdate().then(() => {
      });
      interval(10 * 60 * 1000).subscribe(() => {
        this.updates.checkForUpdate().then(() => {
        });
      });
    }

  }

  public checkForUpdates(): void {
    this.updates.available.subscribe(event => this.promptUser(event));
  }

  private promptUser(event): void {
    this.updates.activateUpdate().then(() => {
      this.availableUpdateAlert();
    });
  }


  private async availableUpdateAlert() {
    const toast = await this.toastController.create({
      mode: 'ios',
      message: 'Hay una actualización disponible',
      position: 'bottom',
      color: 'dark',
      buttons: [
        {
          side: 'end',
          icon: 'refresh',
          text: 'Actualizar',
          handler: () => {
            document.location.reload();
          }
        }
      ]
    });
    toast.present();
  }


}
