import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
export class PublicRedirectionGuard {
    constructor(router) {
        this.router = router;
    }
    canActivate(next, state) {
        if (!environment.protected) {
            this.router.navigateByUrl('service-index');
            return false;
        }
        return true;
    }
}
PublicRedirectionGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PublicRedirectionGuard_Factory() { return new PublicRedirectionGuard(i0.ɵɵinject(i1.Router)); }, token: PublicRedirectionGuard, providedIn: "root" });
