import { Injectable } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { map, mapTo } from 'rxjs/operators';
import { LocaleService } from 'src/app/_services/locale.service';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CollectiveDataProvider {

  public hidrated: boolean = false;
  private logo = null;
  private logosm = null;
  private publicBackground = null;
  private bannerBackground = null;
  private serviceEmail = null;
  private serviceTelephone = null;
  private emergencyTelephone = null;
  private genderViolenceTelephone = null;
  private dataSource = new BehaviorSubject({});

  constructor(
    private localeService: LocaleService
  ) { }

  bindData(data) {
    this.logo = data.logo;
    this.logosm = data.logosm;
    this.publicBackground = data.publicBackground;
    this.bannerBackground = data.bannerBackground;
    this.serviceEmail = data.serviceEmail;
    this.serviceTelephone = data.serviceTelephone;
    this.emergencyTelephone = data.emergencyTelephone;
    this.genderViolenceTelephone = data.genderViolenceTelephone;
    this.dataSource.next(data);
    this.hidrated = true;
  }

  getData() {
    return this.dataSource.asObservable();
  }

  getPublicBackground() {
    return this.publicBackground;
  }

  getBannerBackground() {
    return this.bannerBackground;
  }

  getLogo() {
    return this.logo;
  }

  getLogosm() {
    return this.logosm;
  }

  getServiceEmail() {
    return this.serviceEmail;
  }

  getServiceTelephone() {
    return this.serviceTelephone;
  }

  getEmergencyTelephone() {
    return this.emergencyTelephone;
  }
  getGenderViolenceTelephone() {
    return this.genderViolenceTelephone;
  }



}
