import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "./_guards/auth.guard";
import { SociosanitaryGuard } from "./_guards/sociosanitary.guard";
import { environment } from "src/environments/environment"
import { PublicRedirectionGuard } from "./_guards/public-redirection.guard";

const routes: Routes = [
  {
    path: "",
    redirectTo: "/" + environment.defaultLang,
    pathMatch: "full"
  },
  {
    path: ":locale",
    data: {title: "Acceso"},
    loadChildren: () => import("./_modules/login/login.module").then( m => m.LoginPageModule),
    canActivate: [PublicRedirectionGuard]
  },
  {
    path: ":locale/recovery",
    data: {title: "Recuperar contraseña"},
    loadChildren: () => import("./_modules/recovery/recovery.module").then( m => m.RecoveryPageModule),
    canActivate: [PublicRedirectionGuard]
  },
  {
    path: ":locale/change-password/:token",
    data: {title: "Cambiar contraseña"},
    loadChildren: () => import("./_modules/changepassword/changepassword.module").then( m => m.ChangepasswordPageModule),
    canActivate: [PublicRedirectionGuard]
  },
  {
    path: ":locale/about",
    data: {title: "about", type: "0"},
    loadChildren: () => import("./_modules/page-details/page-details.module").then(m => m.PageDetailsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: ":locale/beneficiaries",
    data: {title: "beneficiaries", type: "1"},
    loadChildren: () => import("./_modules/page-details/page-details.module").then(m => m.PageDetailsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: ":locale/faq",
    data: {title: "faq", type: "2"},
    loadChildren: () => import("./_modules/page-details/page-details.module").then(m => m.PageDetailsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: ":locale/id-4",
    data: {title: "id-4", type: "3"},
    loadChildren: () => import("./_modules/page-details/page-details.module").then(m => m.PageDetailsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: ":locale/id-5",
    data: {title: "id-5", type: "4"},
    loadChildren: () => import("./_modules/page-details/page-details.module").then(m => m.PageDetailsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: ":locale/service-index",
    data: {title: "Servicios"},
    loadChildren: () => import("./_modules/service-index/service-index.module").then( m => m.ServiceIndexPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: ":locale/service-details/:id",
    data: {title: "Servicio id"},
    loadChildren: () => import("./_modules/service-details/service-details.module").then( m => m.ServiceDetailsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: ":locale/service-request/:id",
    data: {title: "Solicitud de servicio id"},
    loadChildren: () => import("./_modules/service-request/service-request.module").then( m => m.ServiceRequestPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: ":locale/contact",
    data: {title: "Contacto"},
    loadChildren: () => import("./_modules/contact/contact.module").then( m => m.ContactPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: ":locale/feature/:id",
    data: {title: "feature id"},
    loadChildren: () => import("./_modules/feature/feature.module").then( m => m.FeaturePageModule),
    // canActivate: [AuthGuard]
  },
  {
    path: ":locale/sociosanitary",
    data: {title: "Formulario ficha sociosanitaria"},
    loadChildren: () => import("./_modules/sociosanitary/sociosanitary.module").then( m => m.SociosanitaryPageModule),
    canActivate: [SociosanitaryGuard, AuthGuard]
  },
  {
    path: "**",
    redirectTo: "/" + environment.defaultLang,
    pathMatch: "full"
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
