import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { filter, map, switchMap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@angular/platform-browser";
export class TitleService {
    constructor(router, activeRoute, title) {
        this.router = router;
        this.activeRoute = activeRoute;
        this.title = title;
        this.environment = environment;
        this.default_title = environment.appName;
    }
    boot() {
        this.router.events.pipe(filter(event => event instanceof NavigationEnd), map(() => this.activeRoute), map(route => route.firstChild), switchMap(route => route.data), map((data) => {
            return data && data.title ? `${data.title} • ${this.default_title}` : this.default_title;
        })).subscribe((current_title) => {
            this.title.setTitle(current_title);
        });
    }
    setTitle(title) {
        this.title.setTitle(title + ' • ' + this.default_title);
    }
}
TitleService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TitleService_Factory() { return new TitleService(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i1.ActivatedRoute), i0.ɵɵinject(i2.Title)); }, token: TitleService, providedIn: "root" });
