import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../_services/auth.service';
import { AlertController } from '@ionic/angular';
import { take, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SociosanitaryGuard implements CanActivate {

  constructor(
    private auth: AuthService,
    private router: Router,
    private alertCtrl: AlertController
  ) {

  }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.auth.user.pipe(
      take(1),
      map(user => {
        if (!user) {
          return false;
        }
        if (!this.auth.getUser().hasOwnProperty('socioSanitaryProfile')) {
          this.router.navigateByUrl('/service-index');
          return false;
        }
        return true;
      })
    )
  }
}
