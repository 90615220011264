import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Events } from '@ionic/angular';
import { AuthService } from './auth.service';
import { LocaleService } from './locale.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@ionic/angular";
import * as i3 from "./auth.service";
import * as i4 from "./locale.service";
export class GroupServicesService {
    constructor(http, events, auth, localeService) {
        this.http = http;
        this.events = events;
        this.auth = auth;
        this.localeService = localeService;
    }
    getCategories() {
        const serviceUrl = `${environment.apiUrl}/${environment.apiVersion}/categories`;
        let authToken = this.auth.getAuthToken();
        const headers = new HttpHeaders().set('Authorization', `Bearer ${authToken}`);
        return this.http.get(serviceUrl, { headers: headers })
            .subscribe(data => {
            this.events.publish('categoriesreceived', data['data']);
        });
    }
    getCategory(id) {
        const serviceUrl = `${environment.apiUrl}/${environment.apiVersion}/category/${id}`;
        let authToken = this.auth.getAuthToken();
        const headers = new HttpHeaders().set('Authorization', `Bearer ${authToken}`);
        return this.http.get(serviceUrl, { headers: headers });
    }
    getAllByCategory(categoryId) {
        const serviceUrl = `${environment.apiUrl}/${environment.apiVersion}/services-by-category/${categoryId}`;
        let authToken = this.auth.getAuthToken();
        const headers = new HttpHeaders().set('Authorization', `Bearer ${authToken}`);
        return this.http.get(serviceUrl, { headers: headers })
            .subscribe(data => {
            this.events.publish('servicesbycategoryreceived', data['data']);
        });
    }
    getAll() {
        let serviceUrl = `${environment.apiUrl}/${environment.apiVersion}`;
        let headers = null;
        if (environment.apiVersion == 'v2') {
            serviceUrl += '/services';
            let authToken = this.auth.getAuthToken();
            headers = new HttpHeaders().set('Authorization', `Bearer ${authToken}`);
        }
        if (environment.apiVersion == 'v3') {
            if (!environment.protected) {
                serviceUrl += '/anonymous';
                headers = new HttpHeaders().set('api-key', environment.apiKey);
            }
            else {
                let authToken = this.auth.getAuthToken();
                headers = new HttpHeaders().set('Authorization', `Bearer ${authToken}`);
            }
            serviceUrl += '/services/list';
        }
        return this.http.get(serviceUrl, { headers: headers })
            .subscribe(data => {
            this.events.publish('servicesreceived', data['data']);
        });
    }
    getOne(id) {
        const serviceUrl = `${environment.apiUrl}/${environment.apiVersion}/service/${id}`;
        let authToken = this.auth.getAuthToken();
        const headers = new HttpHeaders().set('Authorization', `Bearer ${authToken}`);
        return this.http.get(serviceUrl, { headers: headers });
    }
    getCategoriesAndServices() {
        let serviceUrl = `${environment.apiUrl}/${environment.apiVersion}`;
        let headers = null;
        if (environment.apiVersion == 'v2') {
            serviceUrl = `${environment.apiUrl}/${environment.apiVersion}/services-grouped-by-categories`;
            let authToken = this.auth.getAuthToken();
            headers = new HttpHeaders().set('Authorization', `Bearer ${authToken}`);
        }
        if (environment.apiVersion == 'v3') {
            if (!environment.protected) {
                serviceUrl += '/anonymous';
                headers = new HttpHeaders().set('api-key', environment.apiKey);
            }
            else {
                let authToken = this.auth.getAuthToken();
                headers = new HttpHeaders().set('Authorization', `Bearer ${authToken}`);
            }
            serviceUrl += '/services/list-grouped';
        }
        return this.http.get(serviceUrl, { headers: headers })
            .subscribe(data => {
            this.events.publish('categoriesandservicesreceived', data['data']);
        });
    }
}
GroupServicesService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GroupServicesService_Factory() { return new GroupServicesService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.Events), i0.ɵɵinject(i3.AuthService), i0.ɵɵinject(i4.LocaleService)); }, token: GroupServicesService, providedIn: "root" });
