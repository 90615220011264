import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Events } from '@ionic/angular';
import { AuthService } from './auth.service';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { LocaleService } from './locale.service';


@Injectable({
  providedIn: 'root'
})
export class GroupServicesService {

  constructor(
    private http: HttpClient,
    private events: Events,
    private auth: AuthService,
    private localeService: LocaleService
  ) {
  }

  getCategories() {
    const serviceUrl = `${environment.apiUrl}/${environment.apiVersion}/categories`;
    let authToken = this.auth.getAuthToken();
    const  headers = new  HttpHeaders().set('Authorization', `Bearer ${authToken}`);
    return this.http.get(serviceUrl, {headers: headers})
    .subscribe(
      data => {
        this.events.publish('categoriesreceived', data['data']);
      }
    );
  }

  getCategory(id: number) {
    const serviceUrl = `${environment.apiUrl}/${environment.apiVersion}/category/${id}`;
    let authToken = this.auth.getAuthToken();
    const  headers = new  HttpHeaders().set('Authorization', `Bearer ${authToken}`);
    return this.http.get(serviceUrl, {headers: headers});
  }

  getAllByCategory(categoryId) {
    const serviceUrl = `${environment.apiUrl}/${environment.apiVersion}/services-by-category/${categoryId}`;
    let authToken = this.auth.getAuthToken();
    const  headers = new  HttpHeaders().set('Authorization', `Bearer ${authToken}`);
    return this.http.get(serviceUrl, {headers: headers})
    .subscribe(
      data => {
        this.events.publish('servicesbycategoryreceived', data['data']);
      }
    );
  }

  getAll() {
    let serviceUrl = `${environment.apiUrl}/${environment.apiVersion}`;
    let headers = null;

    if (environment.apiVersion == 'v2') {
      serviceUrl += '/services';
      let authToken = this.auth.getAuthToken();
      headers = new  HttpHeaders().set('Authorization', `Bearer ${authToken}`);
    }

    if (environment.apiVersion == 'v3') {
      if (!environment.protected) {
        serviceUrl += '/anonymous';
        headers = new  HttpHeaders().set('api-key', environment.apiKey);
      } else {
        let authToken = this.auth.getAuthToken();
        headers = new  HttpHeaders().set('Authorization', `Bearer ${authToken}`);
      }
      serviceUrl += '/services/list';
    }

    return this.http.get(serviceUrl, {headers: headers})
    .subscribe(
      data => {
        this.events.publish('servicesreceived', data['data']);
      }
    );
  }


  getOne(id: number) {
    const serviceUrl = `${environment.apiUrl}/${environment.apiVersion}/service/${id}`;
    let authToken = this.auth.getAuthToken();
    const  headers = new  HttpHeaders().set('Authorization', `Bearer ${authToken}`);
    return this.http.get(serviceUrl, {headers: headers});
  }

  getCategoriesAndServices() {
    let serviceUrl = `${environment.apiUrl}/${environment.apiVersion}`;
    let headers = null;

    if (environment.apiVersion == 'v2') {
      serviceUrl = `${environment.apiUrl}/${environment.apiVersion}/services-grouped-by-categories`;
      let authToken = this.auth.getAuthToken();
      headers = new  HttpHeaders().set('Authorization', `Bearer ${authToken}`);
    }

    if (environment.apiVersion == 'v3') {
      if (!environment.protected) {
        serviceUrl += '/anonymous';
        headers = new  HttpHeaders().set('api-key', environment.apiKey);
      } else {
        let authToken = this.auth.getAuthToken();
        headers = new  HttpHeaders().set('Authorization', `Bearer ${authToken}`);
      }
      serviceUrl += '/services/list-grouped';
    }

    return this.http.get(serviceUrl, {headers: headers})
      .subscribe(
        data => {
          this.events.publish('categoriesandservicesreceived', data['data']);
        }
      );
  }
}
