import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';
import { ClickToCallComponent } from './click-to-call.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RecaptchaModule } from '../../_shared/recaptcha/recaptcha.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    RouterModule,
    TranslateModule,
    RecaptchaModule
  ],
  declarations: [ClickToCallComponent],
  exports: [ClickToCallComponent]
})
export class ClickToCallModule {}
