<ion-item *ngIf="showLangSelector && showLabel" class="lang-selector">
  <ion-label *ng class="lang-selector-label">{{ "langselector.name" | translate }}</ion-label>
  <ion-select [value]="locale" placeholder="Idioma" (ionChange)="changeLang($event)">
    <ion-select-option *ngFor="let lang of langs" [value]="lang">{{ 'langselector.' + lang | translate }}</ion-select-option>
  </ion-select>
</ion-item>


<ion-select *ngIf="showLangSelector && !showLabel" [value]="locale" placeholder="Idioma" (ionChange)="changeLang($event)">
  <ion-select-option *ngFor="let lang of langs" [value]="lang">{{ 'langselector.' + lang | translate }}</ion-select-option>
</ion-select>
