import { Injectable } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { filter, map, switchMap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
declare var gtag;

@Injectable({
  providedIn: 'root'
})
export class TitleService {
  private environment = environment;
  default_title = environment.appName;

  constructor(
    private router: Router,
    private activeRoute: ActivatedRoute,
    private title: Title
  ) { }

  boot() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.activeRoute),
      map(route => route.firstChild),
      switchMap(route => route.data),
      map((data) => {
        return data && data.title ? `${data.title} • ${this.default_title}` : this.default_title;
      })
    ).subscribe((current_title) => {
      this.title.setTitle(current_title);
    });
  }

  setTitle(title) {
    this.title.setTitle(title + ' • ' + this.default_title);
  }
}
