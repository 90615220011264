import { Injectable } from '@angular/core';
import { NgcCookieConsentService, NgcStatusChangeEvent } from 'ngx-cookieconsent';
import { environment } from '../../environments/environment';
declare var gtag;

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  constructor(
    private ccService: NgcCookieConsentService
  ) {
    this.ccService.statusChange$.subscribe(
      (event: NgcStatusChangeEvent) => {
        if (this.ccService.hasConsented()) {
          this.startGtagTracker();
        }
        window['ga-disable-' + environment.analyticsId] = !this.ccService.hasConsented();
      }
    );
  }

  startGtagTracker() {
    if (!this.enabled() || !this.ccService.hasConsented()) {
      return;
    }
    window['ga-disable-' + environment.analyticsId] = false;
    gtag('js', new Date());
    gtag('config', environment.analyticsId, {
      // todo: no consigo que funcione, siempre trackea page_view
      send_page_view: true
    });
  }

  trackView(pageUrl: string, screenName: string) {
    /*
    console.log('----> trackView ' + pageUrl + ', ' + screenName);

    if (!this.enabled()) {
      return;
    }
    // resulta en mediciones duplicadas porque no consigo que funcione send_page_view: false
    gtag('event', 'page_view', {
      page_title: '--' + screenName,
      // page_location: '<Page Location>',
      page_path: pageUrl,
      send_to: environment.app.analytics.id
    })
    */
  }

  enabled() {
    return environment.analyticsStatus;
  }

}
