import { LocaleService } from '../_services/locale.service';
import * as i0 from "@angular/core";
import * as i1 from "../_services/locale.service";
export class FeaturesDataProvider {
    constructor(localeService) {
        this.localeService = localeService;
        this.features = [];
        this.translatedFeatures = [];
    }
    setFeatures(features) {
        this.features = features;
    }
    getFeatures() {
        return this.features;
    }
    getTranslatedFeatures() {
        if (this.features.length == 0) {
            return;
        }
        this.translatedFeatures = [];
        this.features.forEach(service => {
            this.translatedFeatures.push(this.getTranslatedFeature(service.id));
        });
        return this.translatedFeatures;
    }
    getById(id) {
        return this.features.find(feature => feature.id === id);
    }
    getTranslatedFeature(id) {
        if (this.features.length == 0) {
            return;
        }
        let translatedFeature = Object.assign({}, this.getById(id));
        let locale = this.localeService.getLocale();
        if (translatedFeature.translations.length > 0) {
            let localeTranslation = translatedFeature.translations.find((translation) => translation.locale === locale);
            if (localeTranslation) {
                translatedFeature.name = localeTranslation.name;
                translatedFeature.description = localeTranslation.description;
                translatedFeature.bypassLink = localeTranslation.bypassLink;
            }
        }
        return translatedFeature;
    }
}
FeaturesDataProvider.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FeaturesDataProvider_Factory() { return new FeaturesDataProvider(i0.ɵɵinject(i1.LocaleService)); }, token: FeaturesDataProvider, providedIn: "root" });
