import { Injectable } from '@angular/core';
import { Opinion } from '../_models/opinion';
import { LocaleService } from '../_services/locale.service';


@Injectable({
    providedIn: 'root'
})
export class OpinionsDataProvider {
    private opinions: Opinion[] = [];
    private translatedOpinions: Opinion[] = [];

    constructor(
        private localeService: LocaleService
    ) {
    }

    setOpinions (opinions: Opinion[]) {
        this.opinions = opinions;
    }

    getOpinions() {
        return this.opinions;
    }

    getTranslatedOpinions() {
        if (this.opinions.length == 0) {
            return;
        }
        this.translatedOpinions = [];
        this.opinions.forEach(service => {
            this.translatedOpinions.push(this.getTranslatedOpinion(service.id));
        });

        return this.translatedOpinions;
    }

    getById(id: Number) {
        return this.opinions.find(opinion => opinion.id === id);
    }

    getTranslatedOpinion(id: number) {
        if (this.opinions.length == 0) {
            return;
        }
        let translatedOpinion = {...this.getById(id)};
        let locale = this.localeService.getLocale();
        if (translatedOpinion.translations.length > 0) {
            let localeTranslation: any = translatedOpinion.translations.find( (translation: any) => translation.locale === locale );
            if (localeTranslation) {
                translatedOpinion.description = localeTranslation.description;
            }
        }
        return translatedOpinion;
    }
}
