import { NgcCookieConsentService } from 'ngx-cookieconsent';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "ngx-cookieconsent";
export class AnalyticsService {
    constructor(ccService) {
        this.ccService = ccService;
        this.ccService.statusChange$.subscribe((event) => {
            if (this.ccService.hasConsented()) {
                this.startGtagTracker();
            }
            window['ga-disable-' + environment.analyticsId] = !this.ccService.hasConsented();
        });
    }
    startGtagTracker() {
        if (!this.enabled() || !this.ccService.hasConsented()) {
            return;
        }
        window['ga-disable-' + environment.analyticsId] = false;
        gtag('js', new Date());
        gtag('config', environment.analyticsId, {
            // todo: no consigo que funcione, siempre trackea page_view
            send_page_view: true
        });
    }
    trackView(pageUrl, screenName) {
        /*
        console.log('----> trackView ' + pageUrl + ', ' + screenName);
    
        if (!this.enabled()) {
          return;
        }
        // resulta en mediciones duplicadas porque no consigo que funcione send_page_view: false
        gtag('event', 'page_view', {
          page_title: '--' + screenName,
          // page_location: '<Page Location>',
          page_path: pageUrl,
          send_to: environment.app.analytics.id
        })
        */
    }
    enabled() {
        return environment.analyticsStatus;
    }
}
AnalyticsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AnalyticsService_Factory() { return new AnalyticsService(i0.ɵɵinject(i1.NgcCookieConsentService)); }, token: AnalyticsService, providedIn: "root" });
