import { Component, OnInit } from '@angular/core';
import { Platform, Events, ToastController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AuthService } from './_services/auth.service';
import { UpdateService } from './_services/update.service';
import { AnalyticsService } from './_services/analytics.service';
import { environment } from '../environments/environment';
import * as Bowser from "bowser";
import { Router, NavigationEnd, RoutesRecognized, NavigationStart } from '@angular/router';
import { NgcCookieConsentService } from 'ngx-cookieconsent';
import { GroupServicesService } from './_services/group-services.service';
import { ServicesDataProvider } from './_providers/services-data-provider';
import { PagesService } from './_services/pages.service';
import { TitleService } from './_services/title.service';
import { PagesDataProvider } from './_providers/pages-data-provider';
import { TranslateService } from '@ngx-translate/core';
import { LocaleService } from 'src/app/_services/locale.service';
import { CategoriesDataProvider } from './_providers/categories-data-provider';
import { CollectiveService } from './_services/collective.service';
import { CollectiveDataProvider } from './_providers/collective-data-provider';
import { WidgetsService } from './_services/widgets.service';
import { WidgetsDataProvider } from 'src/app/_providers/widgets-data-provider';
import { OpinionsService } from './_services/opinions.service';
import { OpinionsDataProvider } from './_providers/opinions-data-provider';
import { FeaturesDataProvider } from './_providers/features-data-provider';


declare let gtag;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {

  public environment = environment;
  public collectiveConfig = null;
  public appPages = [];
  public appPages2 = [];
  public services = [];
  public categories = [];
  public servicesPages = [];
  public publicCollectiveData = [];
  public loadingServices = true;
  public loadingService = null;
  public showSubmenu = true;
  public user = null;
  public externalTopLink = null;
  public sociosanitaryAlert = null;
  public locale: string = null;
  public messages: object;

  constructor(
    private platform: Platform,
    private events: Events,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private auth: AuthService,
    private groupServicesService: GroupServicesService,
    private servicesDataProvider: ServicesDataProvider,
    private categoriesDataProvider: CategoriesDataProvider,
    private pagesService: PagesService,
    private widgetsService: WidgetsService,
    private pagesDataProvider: PagesDataProvider,
    private updateService: UpdateService,
    private analyticsService: AnalyticsService,
    private toastController: ToastController,
    private router: Router,
    private ccService: NgcCookieConsentService,
    private titleService: TitleService,
    private translateService: TranslateService,
    private localeService: LocaleService,
    private collectiveService: CollectiveService,
    private collectiveDataProvider: CollectiveDataProvider,
    private widgetsDataProvider: WidgetsDataProvider,
    private opinionsService: OpinionsService,
    private opinionsDataProvider: OpinionsDataProvider,
    private featuresDataProvider: FeaturesDataProvider
  ) {
    this.collectiveService.getPublicData().then((e: any) => {
      this.collectiveDataProvider.bindData(e);
      this.collectiveConfig = e;
    });
    this.initializeApp();
  }

  /**
   * https://tinesoft.github.io/ngx-cookieconsent/home
   *
   * ex:
   *
   * {
   *   "cookie": {
   *     "domain": "tinesoft.github.io"
   *   },
   *   "position": "bottom-right",
   *   "theme": "classic",
   *   "palette": {
   *     "popup": {
   *       "background": "#000000",
   *       "text": "#ffffff",
   *       "link": "#ffffff"
   *     },
   *     "button": {
   *       "background": "#f1d600",
   *       "text": "#000000",
   *       "border": "transparent"
   *     }
   *   },
   *   "type": "info",
   *   "content": {
   *     "message": "This website uses cookies to ensure you get the best experience on our website.",
   *     "dismiss": "Got it!",
   *     "deny": "Refuse cookies",
   *     "link": "Learn more",
   *     "href": "https://cookiesandyou.com",
   *     "policy": "Cookie Policy"
   *   }
   * }
   *
   */
  configureCookieConsent() {
    this.ccService.getConfig().type = 'opt-in';
    this.ccService.getConfig().position = 'bottom-right';
    this.ccService.getConfig().theme = 'classic';
    this.ccService.getConfig().content = this.ccService.getConfig().content || {} ;
    this.ccService.getConfig().content.policy = this.messages['guiElements.cookieAlert.policy'];
    this.ccService.getConfig().content.message = this.messages['guiElements.cookieAlert.text'];
    this.ccService.getConfig().content.link = this.messages['guiElements.cookieAlert.link'];
    this.ccService.getConfig().content.allow = this.messages['guiElements.cookieAlert.allow'];
    this.ccService.getConfig().content.deny = this.messages['guiElements.cookieAlert.deny'];
    if (this.collectiveConfig && Object.keys(this.collectiveConfig).length !== 0) {
      this.ccService.getConfig().content.href = this.collectiveConfig.cookiesUrl;
    }
    this.ccService.destroy();
    this.ccService.init(this.ccService.getConfig());
  }

  ngOnInit() {
    this.titleService.boot();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      //this.updateService.checkForUpdates();
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      this.analyticsService.startGtagTracker();

      if (!environment.protected) {
        this.initContents();
      }

      this.events.subscribe('userloggedin',(
        u => {
          this.user = u;
          this.initContents();
        }
      ));

      this.events.subscribe('servicesreceived', (
        e => {
          this.servicesDataProvider.setServices(e);
          this.servicesPages = this.servicesDataProvider.getTranslatedServices();
          this.loadingServices = false;
          this.showSubmenu = false;
        }
      ));

      this.events.subscribe('pagesreceived',(
        p => {
          this.pagesDataProvider.setPages(p);
          this.appPages = this.pagesDataProvider.initPages();
        }
      ));

      this.events.subscribe('widgetsreceived',(
        w => {
          this.widgetsDataProvider.setWidgets(w);
        }
      ));

      this.events.subscribe('opinionsreceived',(
        o => {
          this.opinionsDataProvider.setOpinions(o);
        }
      ));

      this.router.events.subscribe((ev) => {
        if (ev instanceof NavigationStart) {
          if (this.localeService.getLocale() && !ev.url.startsWith('/' + this.localeService.getLocale())) {
            let newUrl = '/' + this.localeService.getLocale() + ev.url;
            this.router.navigate([newUrl]);
          }
        }
        if (ev instanceof NavigationEnd) {
          if (this.checkSociosanitaryProfileStatus()) {
            this.showSociosanitaryAlert();
          }
        }
        if (ev instanceof RoutesRecognized) {
          if (ev.state.root.firstChild.params.locale) {
            let locale = ev.state.root.firstChild.params.locale;
            if (locale && this.environment.availableLangs.indexOf(locale) === -1) {
              this.router.navigate(['/' + this.environment.defaultLang]);
            } else {
              this.localeService.setLocale(locale);
              this.translateService.get([
                'guiElements.iosInstall.header',
                'guiElements.iosInstall.text',
                'guiElements.cookieAlert.link',
                'guiElements.cookieAlert.text',
                'guiElements.cookieAlert.policy',
                'guiElements.cookieAlert.allow',
                'guiElements.cookieAlert.deny'
              ], {})
              .subscribe((messages: object) => {
                this.messages = messages;
                this.configureCookieConsent();
                if (this.checkInstallIos()) {
                  this.installIosAlert()
                }
              });
            }
          }
        }
      });
    });
  }

  initContents() {
    this.initExternalTopLink();
    if (this.servicesPages.length < 1) {
      this.groupServicesService.getAll();
    }
    if (this.appPages.length < 1) {
      this.pagesService.getAll();
    }
    if (this.widgetsDataProvider.getWidgets().length < 1) {
      this.widgetsService.getAll();
    }
    if (this.opinionsDataProvider.getOpinions().length < 1) {
      this.opinionsService.getAll();
    }
    this.appPages2 = [
      {
        title: 'pages.contact.title',
        url: '/contact',
        icon: 'mail',
        class: 'contact'
      }
    ];
    if(this.checkSociosanitaryLink()) {
      this.appPages2.push({
        title: 'pages.sociosanitary.title',
        url: '/sociosanitary',
        icon: 'medkit',
        class: 'sociosanitary'
      });
    }
  }

  initExternalTopLink() {
    if (!this.environment.externalTopLink.status) {
      return;
    }
    this.externalTopLink = this.environment.externalTopLink;
    if (this.environment.externalTopLink.concatIdentifier) {
      this.externalTopLink.link = this.environment['externalTopLink'].url + '/' + btoa(this.user.username);
    }
  }

  toggleSubmenu(): void {
    this.showSubmenu = !this.showSubmenu;
  }

  logout() {
    this.auth.logout();
  }

  async installIosAlert() {
    const toast = await this.toastController.create({
      mode: 'ios',
      header: this.messages['guiElements.iosInstall.header'],
      message: this.messages['guiElements.iosInstall.text'],
      position: 'bottom',
      color: 'dark',
      buttons: [
        {
          text: 'OK',
          role: 'cancel'
        }
      ]
    });
    toast.present();
  }

  checkInstallIos() {
    let isSafari: boolean = false;
    let userAgent = Bowser.parse(window.navigator.userAgent.toLowerCase());

    if (userAgent.browser.name == 'Safari' && userAgent.platform.type == 'mobile') {
      isSafari = true;
    }

    return !this.checkIsStandalone() && isSafari;
  }

  checkIsStandalone() {
    let isStandAlone = 'standalone' in window.navigator && window.navigator['standalone'];

    return isStandAlone;
  }

  checkSociosanitaryLink() {
    if(!this.user) {
      return;
    }
    if(!this.user.hasOwnProperty('socioSanitaryProfile')) {
      return;
    }

    return true;
  }

  checkSociosanitaryProfileStatus() {
    if(!environment.protected) {
      return;
    }
    if(!this.user) {
      return;
    }
    if (this.sociosanitaryAlert) {
      return;
    }
    if(!this.user.hasOwnProperty('socioSanitaryProfile')) {
      return;
    }
    if(this.user.socioSanitaryProfile) {
      return;
    }
    if (localStorage.getItem('flyingSocioSanitaryProfile')) {
      return;
    }
    let urlPrefix = this.localeService.getLocale();
    if (this.router.url == `/${urlPrefix}/sociosanitary` || this.router.url == `/${urlPrefix}/login` || this.router.url == `/${urlPrefix}//recovery` || this.router.url == `/${urlPrefix}/register` || this.router.url == `/${urlPrefix}`) {
      return;
    }

    return true;
  }

  async showSociosanitaryAlert() {
    this.sociosanitaryAlert = await this.toastController.create({
      mode: 'md',
      header: 'Ficha Sociosanitaria',
      message: 'Para disfrutar del servicio SOS de Teleasistencia Internacional obligatoriamente se debe rellenar previamente esta ficha',
      position: 'middle',
      color: 'medium',
      cssClass: 'sociosanitary-alert',
      showCloseButton: true,
      closeButtonText: 'Más tarde',
      buttons: [
        {
          side: 'end',
          text: 'Rellenar',
          role: '',
          handler: () => {
            // this.sociosanitaryAlert = null;
            this.router.navigate(['sociosanitary']);
          }
        }
      ]
    });
    this.sociosanitaryAlert.present();
  }

}
